export default function menu() {
  const menu = document.querySelector(".menu-header");
  const obody = document.body;
  const navegacao = document.querySelector(".navegacao");
  const btnMobile = document.querySelector("#btn-mobile");

  if (navegacao) {
    navegacao.addEventListener("click", function (e) {
      if (e.target.classList.contains("arrow-down")) {
        e.preventDefault();
        const subMenu = e.target
          .closest(".menu-item-has-children")
          .querySelector(".sub-menu");
        subMenu.classList.toggle("active-sub-menu");
        subMenu.querySelectorAll("li a").forEach((item) => {
          if (e.target !== item) return;
          navegacao.classList.remove("active");
          menu.classList.remove("bg-on-menu-open");
        });
      }
    });
  }

  const toggleMenu = (event) => {
    if (event.type === "touchstart") event.preventDefault();
    navegacao.classList.toggle("active");
    menu.classList.toggle("bg-on-menu-open");

    const active = navegacao.classList.contains("active");
    event.currentTarget.setAttribute("aria-expanded", active);

    if (active) {
      event.currentTarget.setAttribute("aria-label", "Fechar menu");
    } else {
      event.currentTarget.setAttribute("aria-label", "Abrir menu");
    }
  };

  btnMobile.addEventListener("click", toggleMenu);

  window.addEventListener("scroll", function () {
    menu.classList.toggle("scrollou", window.scrollY > 150);
  });
}
