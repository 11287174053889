export default function scripsPageServicos() {
  gsap.registerPlugin(ScrollTrigger);

  const dynamicProgress = gsap.timeline({
    paused: true,
    defaults: {
      ease: "none",
    },
  });

  const heightOfSection = document.querySelector('.timeline-wrapper').offsetHeight
    gsap.set('#dynamic-progress', {
        width: heightOfSection,
        // y: heightOfSection / 2 * -1
    });
    dynamicProgress
        .to("#dynamic-progress", {
        value: 25,
        })
        
        .to("#dynamic-progress", {
        value: 50,
        })
    
        .to("#dynamic-progress", {
        value: 75,
        })
        
        .to("#dynamic-progress", {
        value: 100,
        })
   
    // const items = document.querySelectorAll('.timeline-item')

    let mql = window.matchMedia('(max-width: 639px)').matches;
    gsap.to(".timeline-wrapper", {
        // rotate: 180,

        scrollTrigger: {
          trigger: ".banner-sobre",
        //   pin: true,
          start: `${!mql ? 'top' : 'bottom'} top`,
          end: "+="+ (heightOfSection ),
          // markers: true,
          scrub: true,
          onUpdate({ progress }) {

           
            dynamicProgress.progress(progress);
          }
        }
    });






    // SWIPER  
    
    const swiperInvestimentos = new Swiper('.swiper-investimentos', {
      slidesPerView: 1.2,
    spaceBetween: 16,
    grabCursor: true,

    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".custom-next",
      prevEl: ".custom-prev",
    },

    breakpoints: {
      320: {
        slidesPerView: 1.2,
        spaceBetween: 16,
      },

      768: {
        slidesPerView: 2.2,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
    },
    });
}

