export default function scripsPageHome() {
  const swiperServicos = new Swiper(".swiper-servicos", {
    slidesPerView: 1.2,
    spaceBetween: 24,
    grabCursor: true,
    /*centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.custom-next',
      prevEl: '.custom-prev',
    },*/
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
    },
    breakpoints: {
      540: {
        slidesPerView: 1.2,
        spaceBetween: 32,
      },
      768: {
        slidesPerView: 1.5,
        spaceBetween: 32,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
    },
  });

  const swiperInvestimentos = new Swiper(".swiper-investimentos", {
    slidesPerView: 1.2,
    spaceBetween: 16,
    grabCursor: true,

    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".custom-next",
      prevEl: ".custom-prev",
    },

    breakpoints: {
      320: {
        slidesPerView: 1.2,
        spaceBetween: 16,
      },

      768: {
        slidesPerView: 2.2,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
    },
  });

  function tabsSwap(parentWrapper, goTo) {
    // TABS
    let tabs = document.querySelectorAll(`${parentWrapper} .tab-item`);

    console.log(tabs);

    let tabContents = document.querySelectorAll(
      `${parentWrapper} .tab-content`
    );
    const tabContentContainer = document.querySelector(
      `${parentWrapper} .tabs-content-wrapper`
    );

    if (!tabs) return;
    tabs.forEach(function (tab) {
      tab.addEventListener("click", function () {
        let contentId = this.dataset.contentId;
        let content = document.getElementById(contentId);

        tabContents.forEach(function (content) {
          content.classList.remove("active");
        });

        tabs.forEach(function (tab) {
          tab.classList.remove("active");
        });

        this.classList.add("active");
        content.classList.add("active");

        let mediaQuery = window.matchMedia("(max-width: 1024px)").matches;

        if (mediaQuery && goTo) {
          tabContentContainer.scrollIntoView({ behavior: "smooth" });
        }
      });
    });
  }
}
