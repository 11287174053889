export default function modal() {
  const abrirModal = document.querySelectorAll("[data-js-toggle='modal']");

  const fecharModal = document.querySelector("[data-js-toggle='modal-close']");
  const fundoModal = document.querySelector('.modal-fundo');
  const obody = document.body;

  abrirModal.forEach((i) => {
    console.log(i);
    i.addEventListener('click', (e) => {
      e.preventDefault();
      obody.classList.toggle('modal-aberto');
    });
  });

  fundoModal.addEventListener('click', (e) => {
    e.preventDefault();
    if (
      e.target.classList.contains('modal-fundo') ||
      e.target.classList.contains('close-modal')
    ) {
      obody.classList.remove('modal-aberto');
    }
  });
}
