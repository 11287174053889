import Dom from "./modules/constructor";
import scripsPageHome from "./modules/scriptsPaginas/page_home";
import getElemetAndAppend from "./modules/fetchPages";
import menu from "./modules/menu";
import {animaAoScroll, scrollSVG } from "./modules/animation.js";
import modal from "./modules/Modal";
import tabsSwap from "./modules/tabs";
import initCounterUp from "./modules/counter-up.js";
import scripsPageServicos from "./modules/scriptsPaginas/page_servicos";





(async function init() {
  await getElemetAndAppend(
    ".svgs-container",
    `${window.location.origin}/wp-content/themes/theme/templates_base/svg_defs.html`
  );
})();
menu();
// modal();

/*Páginas*/
const pageHome = new Dom().el("#page_home");
const pageSobre = new Dom().el("#page_sobre");
const pageServicos = new Dom().el("#page_servicos");
const pageContato = new Dom().el("#page_contato");

if (pageHome) {
  // ★ HOME
  new Dom().bodyClass("body__home");
  scripsPageHome();
  animaAoScroll();
  scrollSVG();
} else if (pageSobre) {
  new Dom().bodyClass("body__sobre");
  tabsSwap("#sobre");
  animaAoScroll();
} else if (pageServicos) {
  new Dom().bodyClass("body__servicos");
  tabsSwap("#sobre");
  animaAoScroll();

  scripsPageServicos();

} else if (pageContato) {
  new Dom().bodyClass("body__contato");
  tabsSwap("#sobre");
  animaAoScroll();
}

document.addEventListener("DOMContentLoaded", () => {
  document.body.classList.add("dcl");
  initCounterUp();
});
