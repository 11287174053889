export default function tabsSwap(parentWrapper, goTo, elementoTab) {
  console.log(parentWrapper);
  // TABS
  let tabs = document.querySelectorAll(`${parentWrapper} .navtab-item`);
  const timelineFill = document.querySelector('.line-fill');

  let tabContents = document.querySelectorAll(`${parentWrapper} .tab-content`);
  console.log(tabContents);
  const tabContentContainer = document.querySelector(
    `${parentWrapper} .tabs-content-wrapper`,
  );

  if (!tabs) return;
  tabs.forEach(function (tab, index) {
    console.log(index);
    tab.addEventListener('click', function () {
      let contentId = this.dataset.contentId;
      let content = document.getElementById(contentId);

      tabContents.forEach(function (content) {
        content.classList.remove('active');
      });

      tabs.forEach(function (tab) {
        tab.classList.remove('active');
      });

      this.classList.add('active');

      timelineFill.style.height = `${33 * (index + 1)}%`;

      content.classList.add('active');

      let mediaQuery = window.matchMedia('(max-width: 1024px)').matches;

      if (mediaQuery && goTo) {
        tabContentContainer.scrollIntoView({ behavior: 'smooth' });
      }
    });
  });
}
